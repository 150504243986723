import {Front} from "../pages/Front";
import {Soon} from "../pages/Soon";
import {Home} from "../pages/front/Home";
import {Parcours} from "../pages/front/Parcours";
import {Licence} from "../pages/front/Licence";
import {InfoClub} from "../pages/front/InfoClub";
import {Calendrier} from "../pages/front/Calendrier";
import {Contact} from "../pages/front/Contact";
import {Equipement} from "../pages/front/Equipement";
import {Album} from "../pages/front/Album";

const front = {
  path: '/',
  element: <Front />,
  errorElement : <Front />,
  children: [
    {
      path: '',
      element: <Home />
    },
    {
      path: 'parcours',
      element: <Parcours />
    },
    {
      path: 'calendrier',
      element: <Calendrier />
    },
    {
      path: 'equipement',
      element: <Equipement />
    },
    {
      path: 'licence',
      element: <Licence />
    },
    {
      path: 'contact',
      element: <Contact />
    },
    {
      path: 'photo',
      element: <Album />
    },
    {
      path: 'photo/:slug',
      element: <Album />
    },
    {
      path: 'info-club',
      element: <InfoClub />
    },
    {
      path: 'partenaires',
      element: <Soon />
    }
  ]
}

export default front;