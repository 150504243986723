import React from 'react';
import {BoxTitle} from "../../components/front/BoxTitle";
import word from "../../assets/images/word.png";
import pdf from "../../assets/images/pdf.png";

export const Licence = () => {
  return (
    <>
      <div className="__front_box__">
        <BoxTitle title="Licence 2023 / 2024"/>

        <div className="font-1_25 text-center mb-5">
          Documents à remplir et à renvoyer à Christian (adresse sur le bulletin d'adhésion)
        </div>
        <div className="text-center mb-5">
          <a className="me-3" href="/download/adhesion-2023-2024.doc" download="adhesion-2023-2024.doc"><img src={word} className="img-fluid max-w50px" alt="word"/></a>
          <a className="ms-3" href="/download/adhesion-2023-2024.pdf" download="adhesion-2023-2024.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
          <br/>
          <u>Bulletin d’adhésion</u>
        </div>
        <div className="mb-5 d-flex justify-content-around text-center">
          <p className="col text-center">
            <a className="ms-3" href="/download/certificat-medical.pdf" download="certificat-medical.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            Certificat médical
          </p>
          <p className="col text-center">
            <a className="ms-3" href="/download/attestation-reponse.pdf" download="attestation-reponse.pdf"><img src={pdf} className="img-fluid max-w50px" alt="pdf"/></a>
            <br/>
            Attestation de réponse et Questionnaire de santé
          </p>
        </div>
        <div className="font-1_25 text-center">
          <i className="fa fa-warning me-1" />
          <u>ATTENTION</u> :
          si vous n'avez pas renouvellé votre licence au plus tard le 30 septembre 2023<br/>
          vous n'êtes plus couvert par l'assurance.
        </div>
      </div>
    </>
  );
}
