import React from 'react';
import ContentLoader from 'react-content-loader';

export const BureauLoader = () => {
  return (
    <div className="w-100 h-100 border">
      <ContentLoader
        speed={3}
        width="100%"
        height="100%"
        viewBox="0 0 400 150"
        backgroundColor="#e1e1ff"
        foregroundColor="#8b8bf9"
      >
        <rect x="120" y="20" rx="3" ry="3" width="160" height="10"/>
        <rect x="25" y="40" rx="3" ry="3" width="80" height="80"/>
        <rect x="130" y="45" rx="3" ry="3" width="100" height="9"/>
        <rect x="130" y="60" rx="3" ry="3" width="100" height="9"/>
      </ContentLoader>
    </div>
  );
}
