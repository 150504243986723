import React from 'react';
import {useAppSelector} from "../redux/store";
import {Login} from "./auth/Login";
import {Outlet} from "react-router-dom";

export const Admin = () => {
  const accessToken: string | undefined = useAppSelector((state) => state.auth.accessToken);

  return (
    <>
      {accessToken === undefined && (
        <Login/>
      )}

      {accessToken !== undefined && (
        <>
          {accessToken}
          <Outlet/>
        </>
      )}
    </>
  );
}
