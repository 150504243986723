import React, {useEffect} from 'react';
import {LoginForm} from "../../components/auth/LoginForm";
import {Background} from "../../components/Background";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {StorageService} from "../../service/StorageService";
import {loginByRefresh as authRefresh} from "../../redux/auth_reducer";

export const Login = () => {
  const dispatch = useAppDispatch();
  const refresh: boolean = useAppSelector((state) => state.auth.refresh);

  useEffect(() => {
    if (StorageService.hasItem('refreshToken')) {
      dispatch(authRefresh());
    }
  }, [dispatch]);

  return (
    <>
      <Background/>
      <div className="__form-login__">
        <div className="__form-login_container__">
          {refresh && (
            <div className="p-5">
              <div className="loader"></div>
            </div>
          )}
          {!refresh && (
            <>
              <div className="__form-login_lock__ text-center pb-4">
                <i className="fa-solid fa-circle-user"></i>
              </div>
              <LoginForm/>
            </>
          )}
        </div>
      </div>
    </>
  );
}
