import React from 'react';
import c_team_chartres from "../../assets/images/c_team_chartres.jpg";
import info from "../../assets/images/info.jpg";
import img1 from "../../assets/images/5b0c2ea82a73b.jpg";
import img2 from "../../assets/images/5c15612f97e25.jpg";
import {NavLink} from "react-router-dom";
import {MeteoBox} from "../../components/front/MeteoBox";

export const Home = () => {
  return (
    <>
      <div className="__front_box__ d-flex justify-content-between align-items-center">
        <div className="col text-center">
          <img src={c_team_chartres} className="img-fluid max-w200px" alt="logo"/>
        </div>
        <div className="col text-center">
          <MeteoBox />
        </div>
        <div className="col text-center">
          <NavLink to="/info-club" title="Info du club">
            <img src={info} className="img-fluid image-clignote max-w150px" alt="info"/>
          </NavLink>
        </div>
      </div>
      <div className="mt-4 d-flex justify-content-between align-items-center">
        <div className="col-6 pt-1 pe-3">
          <img src={img1} className="img-fluid img-thumbnail" alt="club"/>
        </div>
        <div className="col-6 pt-1 ps-3">
          <img src={img2} className="img-fluid img-thumbnail" alt="club"/>
        </div>
      </div>
    </>
  );
}