import React from 'react';
import {NavLink} from "react-router-dom";

export const Header = () => {
  return (
    <>
      <h1 className="__front_h1__"><NavLink to="/" title="C’Team Chartres">C’Team Chartres</NavLink></h1>
      <div className="__header_nav__ bg-primary mb-4">
        <div className="__front_navbar_container__ container">
          <div className="d-none d-md-flex justify-content-between __front_navbar__">
            <NavLink to="/parcours" title="Parcours" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Parcours</NavLink>
            <NavLink to="/calendrier" title="Calendrier" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Calendrier</NavLink>
            <NavLink to="/equipement" title="Equipement" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Equipement</NavLink>
            <NavLink to="/licence" title="Licence" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Licence</NavLink>
            <NavLink to="/contact" title="Nous contacter" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Contact</NavLink>
            <NavLink to="/photo" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' }) }>Photos</NavLink>
            <a href="https://www.facebook.com/teamchartrescyclo/" target="_blank" rel="noreferrer">Facebook</a>
          </div>
        </div>
      </div>
    </>
  );
}
